import React from "react";
import Layout from "../layout/Layout";

function success_subscribe({ location }) {
  return (
    <Layout>
      {location?.state?.status ? (
        <>
          <h1>Thanks for subscribe</h1>
          <p>Thank you for subscribe</p>
        </>
      ) : (
        <>
          <h3>OOPS....</h3>
          <p>
            The email address is already subscribed to receive our newsletter
          </p>
        </>
      )}
    </Layout>
  );
}

export default success_subscribe;
